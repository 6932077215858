import * as Sentry from "@sentry/astro";

Sentry.init({
  dsn: "https://2d8dce823c094e8b9733997b0bdf3f86@sentry.vgnett.no/108",
  debug: false,
  environment: "production",
  release: import.meta.env.PUBLIC_VERCEL_GIT_COMMIT_SHA,
  tracesSampleRate: 0,
  sampleRate: 1,
  integrations: [Sentry.browserTracingIntegration()],
  replaysSessionSampleRate: 0,
  replaysOnErrorSampleRate: 0,
});